<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <v-btn
              class="ma-2"
              dark
              color="secondary"
              outlined
              @click="$router.back()"
            >
              <v-icon>mdi-arrow-left</v-icon>
              Back
            </v-btn>
          </div>
        </v-card-title>
        <v-card-title>Information</v-card-title>
        <v-card-title>
          <v-row no-gutter>
            <v-col>
              <v-form ref="form" v-model="el.validForm" lazy-validation>
                <div>
                  <v-text-field
                    v-model="information.url_title"
                    type="text"
                    label="URL title"
                    placeholder="e.g your-information-url-title"
                    :rules="[rule.required]"
                    outlined
                  />
                </div>
                <div>
                  <v-text-field
                    v-model="information.title"
                    type="text"
                    label="Title"
                    :rules="[rule.required]"
                    outlined
                  />
                </div>
                <div>
                  <v-text-field
                    v-model="information.title_en"
                    type="text"
                    label="Title (en)"
                    :rules="[rule.required]"
                    outlined
                  />
                </div>
                <div>
                  <v-text-field
                    v-model="information.site_title"
                    type="text"
                    label="Site title"
                    outlined
                  />
                </div>
                <div>
                  <v-text-field
                    v-model="information.meta_description"
                    type="text"
                    label="Meta description"
                    outlined
                  />
                </div>
                <div>
                  <v-text-field
                    v-model="information.meta_keywords"
                    type="text"
                    label="Meta keywords"
                    outlined
                  />
                </div>
                <v-btn
                  @click="save"
                  :loading="getInformationLoading"
                  color="primary"
                  elevation="2"
                  block
                >
                  {{ isCreate ? "Save" : "Update" }} information
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-card-title>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import rule from "@/shared/rules";
import store from "@/store";
import _, { omit } from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Information"
  },
  data() {
    return {
      rule,
      id: _.get(this.$route, "params.id"),
      information: {
        url_title: null,
        title: null,
        title_en: null,
        site_title: null,
        meta_keywords: null,
        meta_description: null
      },
      el: {
        validForm: false
      }
    };
  },
  mounted() {
    if (!this.isCreate) {
      this.information = this.getInformation(this.id);
    }
  },
  methods: {
    ...mapActions(["createInformation", "updateInformation"]),
    save() {
      if (!this.$refs.form.validate()) {
        store.dispatch("showSnackbar", "One or more fields are invalid.");
        return;
      }

      const payload = omit(this.information, [
        "id",
        "created_at",
        "updated_at"
      ]);
      this.isCreate
        ? this.createInformation(payload)
        : this.updateInformation({ id: this.id, payload });
    }
  },
  computed: {
    ...mapGetters(["getInformation", "getInformationLoading"]),
    isCreate() {
      return _.get(this.$route, "meta.kind") === "create";
    }
  }
};
</script>

<style lang="scss" scoped></style>
